import { createWebHistory, createRouter } from "vue-router";
import RegisterForm from '../components/RegisterForm.vue'
import DeclinePage from '../components/DeclinePage.vue'
import ExtraInformation from "../components/ExtraInformation.vue";
import Signed24 from "../components/Signed24.vue";
import NotFound from '../views/NotFound.vue'
import Maintenance from "../components/Maintenance.vue";

const routes = [
    {
        path: "/",
        name: "Registreren",
        component: RegisterForm,
    },
    {
        path: "/afmelden",
        name: "Afmelden",
        component: DeclinePage,
    },
    {
        path: "/extra-information",
        name: "Extra Information",
        component: ExtraInformation
    },
    {
        path: "/signup",
        name: "AanmeldenSigned",
        component: Signed24
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: NotFound
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});


const allowedDomains = [
    /^localhost(:\d+)?$/,
    /^.*\.signed\.crafture\.com$/,
    /^deploy-preview-\d+--.*\.netlify\.app$/  
]

router.beforeEach((to, from, next) => {
    if (to.name === 'AanmeldenSigned') {
        const currentDomain = window.location.hostname
        const isAllowed = allowedDomains.some(pattern => pattern.test(currentDomain))
        
        if (!isAllowed) {
            // Redirect to 404 page if not an allowed domain
            return next({ name: 'NotFound' })
        }
    }
    next()
})
export default router;
